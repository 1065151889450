/* tslint:disable */
/* eslint-disable */
/**
 * Morphmarket APIs
 * API documentation generated by DRF spectacular
 *
 * The version of the OpenAPI document: 1.0.0 (v1)
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    BaseWeather,
    BaseWeatherFromJSON,
    BaseWeatherFromJSONTyped,
    BaseWeatherToJSON,
} from './BaseWeather';

/**
 * 
 * @export
 * @interface BaseWeatherResponse
 */
export interface BaseWeatherResponse {
    /**
     * 
     * @type {BaseWeather}
     * @memberof BaseWeatherResponse
     */
    postalCode1Weather: BaseWeather;
    /**
     * 
     * @type {BaseWeather}
     * @memberof BaseWeatherResponse
     */
    postalCode2Weather: BaseWeather;
    /**
     * 
     * @type {BaseWeather}
     * @memberof BaseWeatherResponse
     */
    indianapolisHubWeather: BaseWeather;
    /**
     * 
     * @type {BaseWeather}
     * @memberof BaseWeatherResponse
     */
    memphisHubWeather: BaseWeather;
}

export function BaseWeatherResponseFromJSON(json: any): BaseWeatherResponse {
    return BaseWeatherResponseFromJSONTyped(json, false);
}

export function BaseWeatherResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): BaseWeatherResponse {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'postalCode1Weather': BaseWeatherFromJSON(json['postal_code_1_weather']),
        'postalCode2Weather': BaseWeatherFromJSON(json['postal_code_2_weather']),
        'indianapolisHubWeather': BaseWeatherFromJSON(json['indianapolis_hub_weather']),
        'memphisHubWeather': BaseWeatherFromJSON(json['memphis_hub_weather']),
    };
}

export function BaseWeatherResponseToJSON(value?: BaseWeatherResponse | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'postal_code_1_weather': BaseWeatherToJSON(value.postalCode1Weather),
        'postal_code_2_weather': BaseWeatherToJSON(value.postalCode2Weather),
        'indianapolis_hub_weather': BaseWeatherToJSON(value.indianapolisHubWeather),
        'memphis_hub_weather': BaseWeatherToJSON(value.memphisHubWeather),
    };
}

