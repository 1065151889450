import { useEffect, useMemo } from 'react';
import { Box } from '@mui/material';
import { HubAddressT } from 'pages/Ship/Generic/helpers/types';
import { useShipment } from 'pages/Ship/Shipping/Add/hooks/useShipment';
import { HubAddress } from 'pages/Ship/UserShippingAddress/components/EditHubAddress/components/Hubs/HubAddress';
import {
  HubAddressProvider,
  useHubAddress,
} from 'pages/Ship/UserShippingAddress/components/EditHubAddress/hooks/useHubAddress';
import { convertFedexHubDataToHubAddressT } from 'pages/Ship/UserShippingAddress/helpers/transformFedexHubData';
import { WhenWrapper } from 'components/experimental/Wrappers';
import Spinner from 'components/Spinner/Spinner';

const Component = () => {
  const { dropOffLocationPostalCode } = useShipment();
  const { searchFedex, isLoading, fedexData } = useHubAddress();

  useEffect(() => {
    if (dropOffLocationPostalCode) {
      void searchFedex({ postalCode: dropOffLocationPostalCode.split('-')[0] });
    }
  }, [dropOffLocationPostalCode]);

  const hubAddresses: HubAddressT[] = useMemo(
    () => fedexData.map(convertFedexHubDataToHubAddressT),
    [fedexData]
  );

  return (
    <div style={{ width: '100%' }}>
      <WhenWrapper condition={isLoading}>
        <Box sx={{ display: 'flex', justifyContent: 'center', fontSize: 32 }}>
          <Spinner />
        </Box>
      </WhenWrapper>
      {hubAddresses?.map((hubAddress) => (
        <Box key={hubAddress.locationId} mt={2} mb={2}>
          <HubAddress hubAddress={hubAddress} withHubHours={true} withMap={true} />
        </Box>
      ))}
      <WhenWrapper condition={!isLoading && fedexData.length === 0}>
        <Box mt={2}>
          <p>No hubs found</p>
        </Box>
      </WhenWrapper>
    </div>
  );
};

const DropOffLocations = () => {
  return (
    <HubAddressProvider>
      <Component />
    </HubAddressProvider>
  );
};

export default DropOffLocations;
