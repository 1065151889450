import React from 'react';
import { Box, Button } from '@mui/material';
import { HubAddressDetails } from 'pages/Ship/Generic/Form/DisplayFields/DisplayHubAddress/DisplayHubAddress';
import DisplayHubOpenHours from 'pages/Ship/Generic/Form/DisplayFields/DisplayHubOpenHours/DisplayHubOpenHours';
import styles from 'pages/Ship/Generic/Form/DisplayFields/styles.module.scss';
import { HubAddressT } from 'pages/Ship/Generic/helpers/types';

type HubAddressProps = {
  onSelect?: () => void;
  onEdit?: () => void;
  hubAddress: HubAddressT;
  withHubHours?: boolean;
  withMap?: boolean;
};

export const HubAddress: React.FC<HubAddressProps> = ({
  hubAddress,
  onSelect,
  withHubHours = false,
  withMap = false,
  onEdit,
}) => {
  const [showHubHours, setShowHubHours] = React.useState(false);

  const encodedAddress = encodeURIComponent(
    `${hubAddress.address.addressLine1},${hubAddress.address.city},${hubAddress.address.state}+${hubAddress.address.postalCode}`
  );
  const mapLink = `https://www.google.com/maps/place/${encodedAddress}`;

  return (
    <div className={styles.outer}>
      <HubAddressDetails hubAddress={hubAddress} />
      {showHubHours && hubAddress.hubHours && (
        <DisplayHubOpenHours hubHours={hubAddress.hubHours} />
      )}

      <Box display="flex" gap={2} mt={1}>
        {onSelect && (
          <Button onClick={() => onSelect()} variant="contained" color="primary" size="small">
            Select
          </Button>
        )}
        {withHubHours && (
          <Button
            onClick={() => setShowHubHours((prev) => !prev)}
            variant="contained"
            color="primary"
            size="small"
          >
            {showHubHours ? 'Hide' : 'Show'} Store Hours
          </Button>
        )}
        {withMap && (
          <a href={mapLink} rel="noreferrer" target="_blank">
            <Button variant="contained" color="primary" size="small" type="button">
              Map
            </Button>
          </a>
        )}
        {onEdit && (
          <Box mt={1}>
            <Button onClick={() => onEdit()} variant="contained" color="primary" size="small">
              Change
            </Button>
          </Box>
        )}
      </Box>
    </div>
  );
};
